import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './../logo.png';

function Intro() {
	return <Container>
		<Row className="mt-5 mb-5">
			<Col xs={12} md={8} className="align-self-center"><b>Студия Красоты FoxFace</b> представляет собой утонченное сочетание профессиональных знаний и навыков в Косметологии.<br/>В FoxFace мы гарантируем Вам первоклассный сервис, клинически доказанные результаты, а также эксклюзивные бьюти-марки и передовые достижения в сфере красоты.
			</Col>
			{/*<Col md={4}>
				<img src={logo} className="imgw100" />
			</Col>*/}
		</Row>
	</Container>;
}

export default Intro;