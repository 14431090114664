import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './components/header.jsx';
import CarouselBox from './components/carouselBox.jsx';
import Intro from './components/intro.jsx';
import Services from './components/services.jsx';
import Registration from './components/registration.jsx';
import Portfolio from './components/portfolio.jsx';
import Contacts from './components/contacts.jsx';
import Footer from './components/footer.jsx';

function App() {
	return (<>
			<Header/>
			<CarouselBox/>
			<Intro/>
			<Services/>
			<Registration/>
			<Portfolio/>
			<Contacts/>
			<Footer/>
	</>);
}

export default App;
