import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './../logo.png';

function Contacts() {
	return <Container id="contacts" style={{marginTop:'-70px',paddingTop:'70px'}}>
		<Row className="mb-5">
			<Col xs={12} className="mb-3">
				<h2 className="text-center display-3">Контакты</h2>
			</Col>
			<Col xs={12} className="align-self-center">
				<div style={{position:'relative',overflow:'hidden'}}><iframe src="https://yandex.ru/map-widget/v1/?ll=39.019392%2C45.052841&mode=search&oid=112324947626&ol=biz&z=18.69" width="100%" height="480" frameBorder="1" allowFullScreen={true} style={{position:'relative'}}></iframe></div>
			</Col>
		</Row>
	</Container>;
}

export default Contacts;