import {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './../logo.png';

function Header() {
	const [expanded, setExpanded] = useState(false);
	return <header>
		{/*<Navbar expand="lg" className="bg-body-tertiary">*/}
		<Navbar expanded={expanded} expand="lg" className="bgw" fixed="top">
		{/*<Navbar expand="lg" className="bgw" sticky="top">*/}
			<Container>
				<Navbar.Brand href="#top" className="logo-box">
					<img src={logo} className="logo" alt="logo" />
					<span className="name">FoxFace</span>
				</Navbar.Brand>
				<Navbar.Toggle
					onClick={() => setExpanded(expanded ? false : "expanded")}
					aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link href="#promotions" onClick={() => setExpanded(false)} style={{color:'#f00',fontWeight:'bold'}}>Акции</Nav.Link>
						<Nav.Link href="#services" onClick={() => setExpanded(false)}>Услуги</Nav.Link>
						<Nav.Link href="#registration" onClick={() => setExpanded(false)}>Записаться</Nav.Link>
						<Nav.Link href="#portfolio" onClick={() => setExpanded(false)}>Портфолио</Nav.Link>
						<Nav.Link href="#contacts" onClick={() => setExpanded(false)}>Контакты</Nav.Link>
						{/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">
							<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.2">
								Another action
							</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">
								Separated link
							</NavDropdown.Item>
						</NavDropdown>*/}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	</header>;
}

export default Header;