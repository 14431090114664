import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import slider1 from './../img/slider-1.jpg';
import slider2 from './../img/slider-2.jpg';
import slider3 from './../img/slider-3.jpg';
import slider4 from './../img/slider-4.jpg';
import slider5 from './../img/slider-5.jpg';

function CarouselBox() {
	const carouselItemStyle = {
		maxHeight: '550px',
	};
	return (
		<Carousel id="promotions" style={{paddingTop:'70px'}}>
			<Carousel.Item style={carouselItemStyle}>
				{/*<ExampleCarouselImage text="First slide" />*/}
				<img src={slider1} />
				<Carousel.Caption>
					<h3>Губы</h3>
					<p>Аугментация губ сертифицированным препаратом на основе гиалуроновой кислоты <b>7800₽</b>. Специалист подберет индивидуальную технику и форму губ, а так же препарат.</p>{/*До конца месяца спец предложение по выгодной цене!*/}
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item style={carouselItemStyle}>
				{/*<ExampleCarouselImage text="Second slide" />*/}
				<img src={slider2} />
				<Carousel.Caption>
					<h3 style={{color:'#fff'}}>Мезо</h3>
					<p style={{color:'#fff'}}>Отбеливающая мезотерапия - устранения пигментации и осветление кожи достигается путем применения препаратов с янтарной кислотой, глутотионом и аминокислотными комплексами из трех процедур <b>9000₽</b> <s>10500₽</s>.</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item style={carouselItemStyle}>
				{/*<ExampleCarouselImage text="Second slide" />*/}
				<img src={slider3} />
				<Carousel.Caption>
					<h3 style={{color:'#fff'}}>Лифтинг для зрелой кожи с ДМАЭ (диметиламиноэтанол) <b>9000₽</b> <s>10500₽</s>.</h3>
					<p style={{color:'#fff'}}><br/><br/></p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item style={carouselItemStyle}>
				{/*<ExampleCarouselImage text="Second slide" />*/}
				<img src={slider4} />
				<Carousel.Caption>
					<h3 style={{color:'#fff'}}>Липолитики</h3>
					<p style={{color:'#fff'}}>Комплекс процедур по устранению жировых отложений на теле <b>8100₽</b> <s>9000₽</s>.</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item style={carouselItemStyle}>
				{/*<ExampleCarouselImage text="Second slide" />*/}
				<img src={slider5} />
				<Carousel.Caption>
					<h3 style={{color:'#fff'}}>Устранение выпадения волос</h3>
					<p style={{color:'#fff'}}>Комплекс из 5 процедур по устранению выпадения волос <b>9000₽</b> <s>12000₽</s>.</p>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
}

export default CarouselBox;