import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import 'react-phone-number-input/style.css';
import ru from 'react-phone-number-input/locale/ru';
import PhoneInput from 'react-phone-number-input';

import pf1 from './../img/services/1.jpg';
import pf2 from './../img/services/2.jpg';
import pf3 from './../img/services/3.jpg';

export default class Registration extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errorMsg: null,
			clientName: null,
			phoneNumber: null,
			wrongPhone: false,
			validPhone: false,
			showThanks: false
		};

		this.register = this.register.bind(this);
	}

	register() {
		this.setState({
			loading: true,
			errorMsg: null
		}, () => {
			let data = {
				clientName: this.state.clientName,
				phoneNumber: this.state.phoneNumber
			};

			const formdata = new FormData();
			formdata.append('key', '7otJP2FmrH');
			formdata.append('clientName', data.clientName);
			formdata.append('phoneNumber', data.phoneNumber);

			const requestOptions = {
				method: "POST",
				body: formdata,
				redirect: "follow"
			};

			fetch('/register', requestOptions)
				.then((response) => response.text())
				.then((result) => {
					console.log(result);
					this.setState({
						loading: false,
						errorMsg: null,
						clientName: null,
						phoneNumber: null,
						showThanks: true
					});
				})
				.catch((error) => {
					console.error(error);
					this.setState({
						loading: false,
						errorMsg: 'Возникла непредвиденная ошибка, повторите попытку позже',
						showThanks: false
					});
				});

			// setTimeout(()=>{
			// 	this.setState({
			// 		loading:false,
			// 		errorMsg: 'Возникла непредвиденная ошибка, повторите попытку позже',
			// 		showThanks: false
			// 	});
			// },3500);
		});
	}

	render() {
		return <Container id="registration" style={{marginTop:'-70px',paddingTop:'70px'}}>
			<Row className="wrapper mb-5">
				<Col xs={12} className="mb-3">
					<h2 className="text-center display-3">Записаться</h2>
				</Col>
				<Col md={3} lg={4} className="d-xs-none"></Col>
				<Col xs={12} md={6} lg={4} className="mb-3">
					<Card>
						{(this.state.showThanks)?<Card.Body>
							<Card.Title>Сообщение принято, в скором времени с вами свяжутся</Card.Title>
						</Card.Body>:
						<Card.Body>
							{this.state.errorMsg?<Card.Title style={{color:'#f00'}}>{this.state.errorMsg}</Card.Title>:null}
							<FloatingLabel
								controlId="floatingInput"
								label="Ваше имя"
								className="mb-3"
							>
								<Form.Control type="name" placeholder="Ваше имя"onChange={(e)=>{this.setState({clientName:e.target.value})}} disabled={this.state.loading} />
							</FloatingLabel>
							<FloatingLabel
								controlId="floatingInput"
								label="Телефон"
								className="mb-3"
							>
								<PhoneInput
									labels={ru}
									defaultCountry="RU"
									international={true}
									placeholder="Введите телефон"
									className="form-control"
									onChange={(e)=>{this.setState({phoneNumber:e,wrongPhone:!(/^\+79\d{9}/.test(e)),validPhone:(/^\+79\d{9}/.test(e))})}}
									disabled={this.state.loading}
									/>
								{this.state.wrongPhone?<Row><Form.Text style={{color:'#f00'}}>Введите корректный номер</Form.Text></Row>:null}
							</FloatingLabel>
							<div className="spacer"></div>
							<Button disabled={this.state.loading||this.state.wrongPhone||!this.state.validPhone||!this.state.clientName||(this.state.clientName||'').length<3} onClick={()=>{this.register()}} variant="danger" className="mb-2">Записаться</Button>
							<Button onClick={(e)=>{e.preventDefault();window.open('https://api.whatsapp.com/send?phone=79888885859&text=Здравствуйте! Пишу вам с сайта FoxFace.','_blank')}} variant="success" style={{backgroundColor:'#128c7e'}}>Написать в WhatsApp</Button>
						</Card.Body>}
					</Card>
				</Col>
				<Col md={3} lg={4} className="d-xs-none"></Col>
			</Row>
		</Container>;
	}
}

// function Registration() {
// 	return <Container id="registration" style={{marginTop:'-70px',paddingTop:'70px'}}>
// 		<Row className="wrapper mb-5">
// 			<Col xs={12} className="mb-3">
// 				<h2 className="text-center display-3">Записаться</h2>
// 			</Col>
// 			<Col xs={12} md={4} className="mb-3">
// 				<Card>
// 					{/*<Card.Img variant="top" src={pf1} />*/}
// 					<Card.Body>
// 						{/*<Card.Title>Запись на прием</Card.Title>*/}
// 						<FloatingLabel
// 							controlId="floatingInput"
// 							label="Ваше имя"
// 							className="mb-3"
// 						>
// 							<Form.Control type="name" placeholder="Ваше имя" />
// 						</FloatingLabel>
// 						<FloatingLabel
// 							controlId="floatingInput"
// 							label="Телефон"
// 							className="mb-3"
// 						>
// 							<PhoneInput
// 								labels={ru}
// 								defaultCountry="RU"
// 								international={true}
// 								placeholder="Введите телефон"
// 								className="form-control"
// 								onChange={(e)=>{this.setState({phoneNumber:e})}}
// 								/>
// 						</FloatingLabel>
// 						<div className="spacer"></div>
// 						<Button variant="danger">Записаться</Button>
// 					</Card.Body>
// 				</Card>
// 			</Col>
// 		</Row>
// 	</Container>;
// }

// export default Registration;