import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import pf1 from './../img/portfolio/1.jpeg';
import pf2 from './../img/portfolio/2.jpeg';
import pf3 from './../img/portfolio/3.jpeg';
import pf4 from './../img/portfolio/4.jpeg';
import pf5 from './../img/portfolio/5.jpeg';
import pf6 from './../img/portfolio/6.jpeg';
import pf7 from './../img/portfolio/7.jpeg';
import pf8 from './../img/portfolio/8.jpeg';
import pf9 from './../img/portfolio/9.jpeg';
import pf10 from './../img/portfolio/10.jpeg';
import pf11 from './../img/portfolio/11.jpeg';
import pf12 from './../img/portfolio/12.jpeg';
import pf13 from './../img/portfolio/13.jpeg';
import pf14 from './../img/portfolio/14.jpeg';
import pf15 from './../img/portfolio/15.jpeg';
import pf16 from './../img/portfolio/16.jpeg';
import pf17 from './../img/portfolio/17.jpeg';
import pf19 from './../img/portfolio/19.jpeg';
import pf20 from './../img/portfolio/20.jpeg';
import pf21 from './../img/portfolio/21.jpeg';
import pf22 from './../img/portfolio/22.jpeg';

function Portfolio() {
	return <Container id="portfolio" style={{marginTop:'-70px',paddingTop:'70px'}}>
		{/*<Row className="mt-5 mb-5">*/}
		<Row className="mb-5">
			<Col xs={12} className="mb-3">
				<h2 className="text-center display-3">Портфолио</h2>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf1} className="imgw100" rounded />
				<span>Биоревитализация</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf2} className="imgw100" rounded />
				<span>Коллагенотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf3} className="imgw100" rounded />
				<span>Коллагенотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf4} className="imgw100" rounded />
				<span>Мезотерапия Антиакне + пилинги</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf5} className="imgw100" rounded />
				<span>Мезотерапия Антиакне + пилинги</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf6} className="imgw100" rounded />
				<span>Мезотерапия Антиакне + пилинги</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf7} className="imgw100" rounded />
				<span>Мезотерапия Антиакне + пилинги</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf8} className="imgw100" rounded />
				<span>Коллагенотерапия + заполнение морщин мягким филлером + ботулинотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf9} className="imgw100" rounded />
				<span>Коллагенотерапия + пилинги</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf10} className="imgw100" rounded />
				<span>Увеличение губ</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf11} className="imgw100" rounded />
				<span>Увеличение губ</span>
			</Col>
			{/*<Col xs={6} md={4} className="mb-3">
				<Image src={pf12} className="imgw100" rounded />
				<span>Увеличение губ</span>
			</Col>*/}
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf13} className="imgw100" rounded />
				<span>Увеличение губ</span>
			</Col>
			{/*<Col xs={6} md={4} className="mb-3">
				<Image src={pf14} className="imgw100" rounded />
				<span>Увеличение губ</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf15} className="imgw100" rounded />
				<span>Увеличение губ</span>
			</Col>*/}
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf16} className="imgw100" rounded />
				<span>Ботулинотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf17} className="imgw100" rounded />
				<span>Ботулинотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf19} className="imgw100" rounded />
				<span>Ботулинотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf20} className="imgw100" rounded />
				<span>Коррекция средней трети филлерами</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf21} className="imgw100" rounded />
				<span>Ботулинотерапия</span>
			</Col>
			<Col xs={6} md={4} className="mb-3">
				<Image src={pf22} className="imgw100" rounded />
				<span>Лифтинг Нефертити</span>
			</Col>
		</Row>
	</Container>;
}

export default Portfolio;