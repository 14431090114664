import React from 'react';

const Footer = () => <footer className="page-footer font-small blue pt-4">
	<div className="container-fluid text-center text-md-left">
		<div className="row">
			{/*<div className="col-md-3 mb-md-0 mb-3">
				<h5 className="text-uppercase">Links</h5>
				<ul className="list-unstyled">
					<li><a href="#!">Link 1</a></li>
					<li><a href="#!">Link 2</a></li>
					<li><a href="#!">Link 3</a></li>
					<li><a href="#!">Link 4</a></li>
				</ul>
			</div>

			<div className="col-md-3 mb-md-0 mb-3">
				<h5 className="text-uppercase">Links</h5>
				<ul className="list-unstyled">
					<li><a href="#!">Link 1</a></li>
					<li><a href="#!">Link 2</a></li>
					<li><a href="#!">Link 3</a></li>
					<li><a href="#!">Link 4</a></li>
				</ul>
			</div>*/}

			<hr className="clearfix w-100 d-md-none pb-0"/>

			{/*<div className="col-md-6 mt-md-0 mt-3">*/}
			<div className="col-md-12 mt-md-0 mt-3">
				{/*<h5 className="text-uppercase">Footer Content</h5>*/}
				<p style={{color:'#aaa'}}>Вся представленная информация, носит информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437(2) Гражданского кодекса.</p>
			</div>
		</div>
	</div>

	<div className="footer-copyright text-center py-3">© 2020 Copyright: <a href="/">FoxFace</a>
	</div>

</footer>

export default Footer