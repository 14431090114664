import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import pf1 from './../img/services/1.jpg';
import pf2 from './../img/services/2.jpg';
import pf3 from './../img/services/3.jpg';

function Services() {
	return <Container id="services" style={{marginTop:'-70px',paddingTop:'70px'}}>
		<Row className="wrapper mb-5">
			<Col xs={12} className="mb-3">
				<h2 className="text-center display-3">Услуги</h2>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Биоревитализация от отечности и дряблости кожи лица</Card.Title>
						<Card.Text className="mb-0">
							Рекомендуется при отечности, дряблости и сухости кожи лица (в т.ч. вследствие злоупотребления курением, алкоголем, кофе) и влияния на кожу городских и сезонных стресс-факторов, а так же в качестве подготовки к химическим пилингам, лазерной шлифовке.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Биоревитализация от провисания возрастной кожи</Card.Title>
						<Card.Text className="mb-0">
							Рекомендуется при избыточном провисании кожи и нарушении овала лица, при тусклой, уставшей и обезвоженной коже при выраженных возрастных изменениях, при наличии глубоких морщин, тонкой кожи, а так же в качестве подготовки к химическим пилингам, лазерной шлифовке.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Биоревитализация для осветления пигментных пятен</Card.Title>
						<Card.Text className="mb-0">
							Рекомендуется при наличии пигментных пятен, неровного тона кожи. Для увлажнения и уплотнения кожи.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Векторный лифтинг жидкими бионитями</Card.Title>
						<Card.Text className="mb-0">
							Рекомендуется при наличии заломов (морщин), рубцов, постакне. Для подтяжки и уплотнения кожи лица и шеи.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Поверхностные пилинги + альгинатная маска</Card.Title>
						<Card.Text className="mb-0">
							Миндальный, мультифруктовый, феруловый, гликолевый, фитиновый, молочный, азелаиновый, салициловый.<br/>Пилинг рекомендуется для отшелушивания омертвевших клеток, что стимулирует синтез коллагена и эластина для возникновения молодых клеток, способствует общему омоложению кожи, а так же при акне, постакне, рубцах, шрамах, растяжках, мимических, мелких морщин, увядании кожи, потери эластичности кожи, фотостарении, гиперпигментации, кератом.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Срединный пилинг Джесснера</Card.Title>
						<Card.Text className="mb-0">
							Выравнивает поверхность кожи, очищает и сужает поры, убирает застойные пятна и пигментацию, делает кожу лица подтянутой и сияющей, а так же рекомендуется при гиперкератозах (коричневые наросты), выраженных возрастных изменениях.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Безинъекционная омолаживающая процедура + альгинатная маска</Card.Title>
						<Card.Text className="mb-0">
							Рекомендуется при темных кругах и мешках под глазами, тусклом цвете лица, общей усталости кожи и шелушении, при фото и хроностарении, пигментации, расширенных порах, акне, постакне. Рекомендуется как подготовка кожи к биоревитализации и мезотерапии.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Коррекция «малярных мешков» и уставшего вида</Card.Title>
						<Card.Text className="mb-0">
							Гидроксиапатит кальция рекомендуется при провисании средней трети в результате ослабления мышечно-связочного аппарата, гравитационного птоза под действием силы тяжести, накопления жидкости.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={4} className="mb-3">
				<Card>
					{/*<Card.Img variant="top" src={pf1} />*/}
					<Card.Body>
						<Card.Title>Мезотерапия от выпадения волос</Card.Title>
						<Card.Text className="mb-0">
							Рекомендуется при выпадении волос, истончении, ломкости и сухости волос, медленном росте волос, перхоти, себореи, ранней седине. Восстановление волос после коронавирусной инфекции. Профилактика выпадения волос.
						</Card.Text>
						<div className="spacer"></div>
						{/*<Button variant="danger">Записаться</Button>*/}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</Container>;
}

export default Services;